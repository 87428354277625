<template>
  <div>
    <auth-forms-wrapper show-links>
      <template #form>
        <v-row>
          <v-col>
            <v-img
              width="100%"
              justify="center"
              rel="preload"
              align="center"
              class="mx-auto mt-n12"
              :src="$images.loggedOut"
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col class="text-center mt-n12">
            <h3 class="text--primary font--secondary">
              Something Went Wrong!
            </h3>
            <p class="mt-6 mb-0 caption">
              Try again, if the problem still persists
            </p>
            <a class="text-decoration-none body-2" href="mailto:support@maropost.com?subject=Support">
              Contact Support
            </a>
          </v-col>
        </v-row>

        <v-row class="mt-0">
          <v-col class="text-center py-5">
            <v-btn
              block
              x-large
              color="loginBtn"
              class="font-weight-bold white--text"
              @click="onLogin"
              v-track="'keycloak-auth__page-login-again-btn'"
            >
              Login Again
            </v-btn>
          </v-col>
        </v-row>
      </template>
    </auth-forms-wrapper>
  </div>
</template>

<script>
import AuthFormsWrapper from "@/components/shared/AuthFormsWrapper.vue";

/**
 * AuthIssue view
 */
export default {
  name: "AuthIssue",
  /**
  |--------------------------------------------------
  | Data Properties
  |--------------------------------------------------
  */
  data() {
    return {
    };
  },
  /**
  |--------------------------------------------------
  | Components
  |--------------------------------------------------
  */
  components: {
    AuthFormsWrapper,
  },
  methods: {
    onLogin () {
      window.location.replace(window.location.origin);
    }
  }
};
</script>
